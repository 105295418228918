.table-card .card {
  min-height: 400px;
  max-height: 400px;
  overflow: hidden;
}
.table-card-header {
  gap: 10px;
}

.table-card .card-content,
.table-card .table {
  flex-grow: 1;
  overflow: hidden;
}

.table-card .card-content {
  gap: 10px;
}

.table-card .card .export-png {
  max-height: 800px;
  height: 800px;
}
