.chart-heading {
  flex-direction: row;
}
.chart-name {
  font-size: var(--wmg-text-body-size);
  color: var(--wmg-color-white);
}

.chart-scale {
  font-size: var(--wmg-text-caption-size);
  color: var(--wmg-color-grey);
  font-weight: var(--wmg-text-weight-bold);
}

.engagement-context-chart .recharts-surface {
  position: absolute !important;
  left: 0;
  top: 0;
}

.engagement-context-chart .recharts-wrapper svg {
  overflow: inherit !important;
}

.engagement-context-chart .custom-tooltip {
  background: var(--wmg-color-grey2);
  border-radius: var(--wmg-border-radius-medium);
  padding: 8px;
}

.engagement-context-chart .recharts-tooltip-wrapper,
.engagement-context-chart .recharts-tooltip-wrapper div {
  display: block;
}

.engagement-context-chart .custom-tooltip h2 {
  font-size: var(--wmg-text-body-size);
  line-height: var(--wmg-text-body-line-height);
  font-weight: bold;
  margin: 0;
  color: var(--wmg-color-grey);
}

.engagement-context-chart .custom-tooltip div {
  margin-top: 4px;
  max-width: 200px;
}

.engagement-context-chart .custom-tooltip p {
  font-size: var(--wmg-text-caption-small-size);
  line-height: var(--wmg-text-caption-small-line-height);
  margin: 0;
}
