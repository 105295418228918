.export-option-container {
  position: relative;
}

.artist-card .export-option-container {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  justify-content: flex-end;
  margin: 10px;
}

.export .pop-over {
  width: 175px;
  left: auto;
  right: -12px;
}
