.table {
  overflow: hidden;
}

table {
  width: 100%;
}

tr.gap td {
  padding: 10px 0;
}

tr.parent {
  position: sticky !important;
  background-color: var(--wmg-color-background2);
  top: 0;
  z-index: 1;
}

tr.parent,
tr.child {
  position: relative;
}

tr.clickable:hover {
  cursor: pointer;
  background-color: var(--wmg-color-background2);
}

.table-header,
.table-content {
  padding-right: 20px;
}

.table-content {
  /* justify-content: center; */
  overflow-y: auto;
}

.table-content > .row {
  padding: 10px 0;
  border-bottom: 1px solid var(--wmg-color-background3);
}

.table-content > .row:last-child {
  border-bottom: 0;
}

.table-content::-webkit-scrollbar {
  transform: translateX(-10px);
  width: 5px;
}

.table-content::-webkit-scrollbar-track {
  background-color: var(--wmg-color-grey2);
  border-radius: 20px;
}

.table-content::-webkit-scrollbar-thumb {
  background-color: var(--wmg-color-grey);
  border-radius: 20px;
  /* border: 1px solid var(--wmg-color-grey2); */
}
