.filters-container .drawer {
  position: absolute;
  transform: translateY(70px);
  background: var(--wmg-color-background2);
  border-bottom-left-radius: var(--wmg-border-radius-small);
  border-bottom-right-radius: var(--wmg-border-radius-small);
  left: 0;
  right: 0;
  min-height: 200px;
  max-height: 400px;
  z-index: 10;
  top: 0;
  padding: 20px;
  display: none;
  overflow: hidden;
}
