.svg-item {
  position: relative;
  animation: donutfade 1s;
  min-width: 75%;
  min-height: 220px;
  justify-content: center;
  align-items: center;
}

.round {
  overflow: hidden;
  position: absolute;
  transform: rotate(-90deg);
  transition: all 1s ease-in-out;
  fill: transparent;
  /* SVG */
  stroke-width: 8;
  stroke-linecap: butt;
  stroke-dasharray: 0 999;
}

.text-label {
  position: absolute;
  text-anchor: middle;
}
