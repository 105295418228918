.avatar {
  height: 40px;
  width: 40px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.15);
  justify-content: center;
  align-items: center;
  transition: 0.75s all ease;
}

.avatar:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.avatar.clickable {
  cursor: pointer;
}
