.text-input-container {
  display: flex;
  border: 2px solid transparent;
  padding: 2px;
  margin-left: -2px;
  margin-right: -2px;
  border-radius: var(--wmg-border-radius-large);
  transition: 0.5s all ease;
  max-height: 54px;
}

.text-input {
  display: flex;
  border: 1px solid var(--wmg-color-grey2);
  border-radius: var(--wmg-border-radius-medium);
  transition: 0.5s all ease;
  align-items: center;
  gap: 10px;
  /* padding: 0 15px; */
}

.text-input-container.focused .text-input,
.text-input-container:hover .text-input {
  border-color: var(--wmg-color-grey);
}
/* .text-input-container.focused {
  border-color: var(--wmg-color-primary);
} */

.text-input-container.error .text-input {
  border-color: var(--wmg-color-error);
}

.text-input-container.disabled .text-input {
  border-color: var(--wmg-color-grey2);
}

.text-input-container.disabled .text-input input {
  border-color: var(--wmg-color-grey2);
}

.text-input .prefix {
  padding-left: 20px;
  margin-right: -25px;
}

.text-input .prepend {
  padding-left: 8px;
  margin-right: -25px;
}
.text-input .inline-label {
  opacity: 0.5;
  padding-left: 20px;
  white-space: nowrap;
}

.text-input input {
  margin: 0;
  border: none;
  border-radius: var(--wmg-border-radius-medium);
  text-align: left;
  width: 100%;
  padding: 15px 20px;
  font-family: var(--wmg-font-family);
  font-size: var(--wmg-text-body-size);
  line-height: var(--wmg-text-body-line-height);
  color: var(--wmg-color-white) !important;
  background-color: transparent !important;
  color-scheme: dark;
}

.text-input input[type="date"] {
  text-align: right;
}

.text-input input[type="date"]::-webkit-datetime-edit-text,
.text-input input[type="date"]::-webkit-datetime-edit-month-field,
.text-input input[type="date"]::-webkit-datetime-edit-day-field,
.text-input input[type="date"]::-webkit-datetime-edit-year-field {
  color: rgba(255, 255, 255, 0.25);
}

.text-input input[type="date"].input--has-value::-webkit-datetime-edit-text,
.text-input
  input[type="date"].input--has-value::-webkit-datetime-edit-month-field,
.text-input
  input[type="date"].input--has-value::-webkit-datetime-edit-day-field,
.text-input
  input[type="date"].input--has-value::-webkit-datetime-edit-year-field {
  color: #fff;
}

.text-input input[type="date"]:before {
  color: rgba(255, 255, 255, 0.5);
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

.text-input-container.with-icon .text-input {
  padding-left: 15px;
}

.text-input-container.with-icon input {
  padding: 15px 0;
}

.text-input input:-webkit-autofill {
  border: none;
  background-color: transparent !important;
}
.text-input input:focus {
  outline: none;
}

.text-input input::placeholder {
  color: var(--wmg-color-grey);
}

.text-input .icon-button {
  margin-right: 10px;
}
