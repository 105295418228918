.indentation {
  padding: 2px;
  background-color: #666666;
  border-radius: 5px;
  margin-left: -12px;
  height: 95%;
  position: absolute;
}

.child.selected > td {
  background-color: transparent;
}
.child.selected a span {
  color: var(--wmg-color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.child.selected a span:hover {
  text-decoration: underline;
}
