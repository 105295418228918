.pagination .page-item {
  box-sizing: border-box;
  display: flex;
  height: 30px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 0 2px;
  min-width: 30px;
}

.pagination a.page-item:hover,
.pagination .page-item.current {
  background-color: var(--wmg-color-background3);
}

.pagination .page-item.disabled {
  opacity: 0.5;
}
.pagination a.page-item.disabled:hover {
  background-color: transparent;
}
