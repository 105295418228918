.search-bar.fixed {
  position: fixed;
  max-width: var(--wmg-max-page-width);
  width: 100%;
  z-index: 90;
  background: var(--wmg-color-background);
  padding-top: 110px;
  top: 0;
  padding-bottom: 10px;
}

.search-bar.compact .text-input {
  background-color: var(--wmg-color-grey2);
  padding-left: 10px;
}

.search-bar.compact .text-input input {
  padding: 6px 0;
  font-size: var(--wmg-text-caption-size);
  line-height: var(--wmg-text-caption-line-height);
}
