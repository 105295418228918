.table-header .sortable-header {
  align-items: center;
}

.table-header .sortable-header.right {
  justify-content: flex-end;
}

.table-header .sortable-header.left {
  justify-content: flex-start;
}

.table-header .sortable-header.hidden {
  display: none;
}
