.map-svg {
  fill: var(--wmg-color-grey);
  align-items: center;
}

.hover-country {
  fill: var(--wmg-color-primary);
}

.map-file {
  margin-top: 30px;
}

.country-row:hover {
  background: var(--wmg-color-secondary);
  border-radius: 10px;
  border: 1px solid var(--wmg-color-primary);
}

.table-content .country-row {
  padding: 10px;
}

.country-table .table-header {
  padding-right: 30px;
  padding-top: 10px;
}

.country-table {
  height: 90%;
}
