.filters-container {
  position: relative;
  padding: 20px;
  margin-right: -20px;
  border-top-left-radius: var(--wmg-border-radius-small);
  border-top-right-radius: var(--wmg-border-radius-small);
}

.filters-container.active {
  background: var(--wmg-color-background2);
}

.filters-container.active .drawer {
  display: flex;
}
