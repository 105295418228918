.app-container .app-header-container {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  justify-content: center;
}

.app-container .app-header-container.scrolled {
  /* background: var(--wmg-color-background); */
  background: rgba(18, 18, 18, 0.6);
  backdrop-filter: blur(16px);
}
.app-container .app-header {
  padding: 20px 20px 20px 20px;
  max-width: var(--wmg-max-page-width);
  width: calc(100% - 40px);
}

.app-container .app-navigation {
  justify-content: center;
}
