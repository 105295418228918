.tag-group {
  background-color: rgba(20, 60, 99, 0.3);
  border-radius: var(--wmg-border-radius-small);
  padding: 6px;
  color: rgba(255, 255, 255, 0.75);
}

.tag-group .tag.disabled {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.tag {
  background-color: var(--wmg-color-background3);
  color: var(--wmg-color-white);
  align-self: flex-start;
  border-radius: var(--wmg-border-radius-small);
  padding: 8px 16px;
  align-items: center;
  font-size: var(--wmg-text-caption-size);
  white-space: nowrap;
}

.tag.active {
  background-color: var(--wmg-color-white);
  color: var(--wmg-color-background);
}

.tag.selected {
  background-color: var(--wmg-color-secondary);
}

.tag.small {
  padding: 4px 8px;
}
.tag.medium {
  padding: 8px 16px;
}

.tag .count {
  border-radius: 4px;
  background-color: var(--wmg-color-white);
  color: var(--wmg-color-background3);
  padding: 0 6px;
  margin-right: -5px;
}

.tag.active .count {
  background-color: var(--wmg-color-background3);
  color: var(--wmg-color-white);
}

.tag .icon {
  margin-right: -5px;
}
