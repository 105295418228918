.save-collection-container {
  position: relative;
}

.artist-card .save-collection-container {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  justify-content: flex-end;
  margin: 10px;
}

.artist-card .save-collection-button {
  display: flex;
  border-radius: var(--wmg-border-radius-xlarge);
  background-color: rgba(9, 9, 8, 0.35);
  justify-content: center;
  align-items: center;
  height: 48px;
  min-width: 48px;
  opacity: 0;
  transition: 0.5s opacity ease;
}

.save-collection-container.isAdded .save-collection-button,
.artist-card:hover .save-collection-button {
  opacity: 1;
}
