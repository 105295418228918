@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap");

:root {
  --wmg-color-background: rgba(18, 18, 18, 1);
  --wmg-color-background2: rgba(28, 28, 28, 1);
  --wmg-color-background3: rgba(45, 45, 45, 1);
  --wmg-color-background4: rgba(31, 32, 33, 1);
  --wmg-color-primary: rgba(13, 113, 207, 1);
  --wmg-color-primary2: rgba(14, 127, 232, 1);
  --wmg-color-secondary: rgba(20, 60, 99, 1);
  --wmg-color-error: rgba(241, 15, 42, 1);
  --wmg-color-white: rgba(255, 255, 255, 1);
  --wmg-color-grey: rgba(130, 130, 130, 1);
  --wmg-color-grey2: rgba(48, 48, 48, 1);
  --wmg-font-family: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --wmg-text-h1-size: 64px;
  --wmg-text-h1-line-height: 96px;
  --wmg-text-h1-small-size: 36px;
  --wmg-text-h1-small-line-height: 58px;
  --wmg-text-h2-size: 32px;
  --wmg-text-h2-line-height: 48px;
  --wmg-text-h3-size: 24px;
  --wmg-text-h3-line-height: 36px;
  --wmg-text-h4-size: 20px;
  --wmg-text-h4-line-height: 30px;
  --wmg-text-h5-size: 20px;
  --wmg-text-h5-line-height: 30px;
  --wmg-text-body-size: 14px;
  --wmg-text-body-line-height: 20px;
  --wmg-text-caption-size: 13px;
  --wmg-text-caption-line-height: 20px;
  --wmg-text-caption-small-size: 11px;
  --wmg-text-caption-small-line-height: 18px;
  --wmg-text-weight: 500;
  --wmg-text-weight-bold: 700;
  --wmg-border-radius-xsmall: 4px;
  --wmg-border-radius-small: 8px;
  --wmg-border-radius-medium: 12px;
  --wmg-border-radius-large: 16px;
  --wmg-border-radius-xlarge: 24px;
  --wmg-max-page-width: 1264px;
}

#root {
  display: flex;
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: var(--wmg-font-family);
  font-size: var(--wmg-text-body-size);
  font-weight: var(--wmg-text-weight);
  line-height: var(--wmg-text-body-line-height);
  background-color: var(--wmg-color-background);
  color: var(--wmg-color-white);
}

/* Works on Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: var(--wmg-color-grey2) var(--wmg-color-grey);
}

/* Works on Chrome, Edge, and Safari */
body::-webkit-scrollbar {
  width: 14px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--wmg-color-grey);
  border-radius: 20px;
  border: 4px solid var(--wmg-color-background);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

h1 {
  font-size: var(--wmg-text-h1-size);
  font-weight: var(--wmg-text-weight-bold);
  line-height: var(--wmg-text-h1-line-height);
}

h2 {
  font-size: var(--wmg-text-h2-size);
  font-weight: var(--wmg-text-weight-bold);
  line-height: var(--wmg-text-h2-line-height);
}

h3 {
  font-size: var(--wmg-text-h3-size);
  font-weight: var(--wmg-text-weight-bold);
  line-height: var(--wmg-text-h3-line-height);
}

h4 {
  font-size: var(--wmg-text-h4-size);
  font-weight: var(--wmg-text-weight-bold);
  line-height: var(--wmg-text-h4-line-height);
}

h5 {
  font-size: var(--wmg-text-h5-size);
  font-weight: var(--wmg-text-weight);
  line-height: var(--wmg-text-h5-line-height);
}

a {
  text-decoration: none;
  color: var(--wmg-color-white);
}
a.underline:hover {
  text-decoration: underline;
}

div {
  display: flex;
}

.bold {
  font-weight: var(--wmg-text-weight-bold);
}

.caption {
  font-size: var(--wmg-text-caption-size);
  line-height: var(--wmg-text-caption-line-height);
}
.caption.small {
  font-size: var(--wmg-text-caption-small-size);
  line-height: var(--wmg-text-caption-small-line-height);
}

.primary {
  color: var(--wmg-color-primary);
}

.secondary {
  color: var(--wmg-color-grey);
}

.placeholder {
  background-color: var(--wmg-color-background2);
  color: var(--wmg-color-background2);
  border-radius: var(--wmg-border-radius-small);
  animation: pulse 1.5s infinite ease-in-out;
}

h1.placeholder,
h2.placeholder,
h3.placeholder,
h4.placeholder,
h5.placeholder {
  align-self: flex-start;
}

.app-container {
  flex: 1;
}

.no-auth-app-container {
  align-items: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: "#dddddd";
}

.flex {
  display: flex;
  flex: 1;
}

.wrap {
  flex-wrap: wrap;
}

.no-wrap {
  white-space: nowrap;
}

.grow {
  flex-grow: 1;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row.center,
.column.center {
  align-items: center;
}

.column.end {
  align-items: flex-end;
}
.row.end {
  align-items: flex-end;
  justify-content: flex-end;
}

.v-spacing {
  margin-top: 20px;
  margin-bottom: 20px;
}

.gap-s {
  gap: 5px;
}

.gap {
  gap: 10px;
}

.gap-m {
  gap: 20px;
}

.gap-l {
  gap: 30px;
}

.gap-xl {
  gap: 40px;
}

.scrollbar {
  overflow-y: auto;
  padding-right: 20px;
}
.scrollbar::-webkit-scrollbar {
  transform: translateX(-10px);
  width: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: var(--wmg-color-grey2);
  border-radius: 20px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--wmg-color-grey);
  border-radius: 20px;
  /* border: 1px solid var(--wmg-color-grey2); */
}

.sticky-header {
  position: sticky;
  top: 0;
}
.sticky-footer {
  position: sticky;
  bottom: 0;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

table td {
  padding: 5px 0;
  vertical-align: top;
}
