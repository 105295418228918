.artist-detail-background {
  position: fixed;
  top: 0;
  background-size: cover;
  min-width: 100vw;
  height: 100vh;
  max-height: 1000px;
  background-repeat: no-repeat;
  background-position: center center;
}
.artist-detail-header {
  position: fixed;
  width: 100vw;
  top: 92px;
  padding: 0 0 20px 0;
  z-index: 90;
}
.artist-detail-header h1 {
  transition: 0.5s all ease-in-out;
}

.artist-detail-header.scrolled {
  /* background: var(--wmg-color-background); */
  transform: translate3d(0, -20px, 0);
  background: rgba(18, 18, 18, 0.6);
  backdrop-filter: blur(16px);
  top: 97px;
}
.artist-detail-header.scrolled h1,
.artist-detail-header.scrolled.smaller h1 {
  font-size: var(--wmg-text-h2-size);
  line-height: var(--wmg-text-h2-line-height);
}

.artist-detail-header.smaller h1 {
  font-size: var(--wmg-text-h1-small-size);
  line-height: var(--wmg-text-h1-small-line-height);
}

.artist-detail-header > .row {
  align-self: center;
  max-width: var(--wmg-max-page-width);
  width: calc(100% - 40px);
}
