.nav-item {
  border-bottom: 2px solid transparent;
}

.nav-item,
.nav-item > a {
  transition: 0.75s all ease;
}

.nav-item:hover,
.nav-item.active {
  border-bottom-color: var(--wmg-color-white);
}

.nav-item > a {
  color: rgba(255, 255, 255, 0.5);
}

.nav-item:hover > a,
.nav-item.active > a {
  color: var(--wmg-color-white);
}
