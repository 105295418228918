@import url(https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.icon-button {
  cursor: pointer;
}

.text-input-container {
  display: flex;
  border: 2px solid transparent;
  padding: 2px;
  margin-left: -2px;
  margin-right: -2px;
  border-radius: var(--wmg-border-radius-large);
  transition: 0.5s all ease;
  max-height: 54px;
}

.text-input {
  display: flex;
  border: 1px solid var(--wmg-color-grey2);
  border-radius: var(--wmg-border-radius-medium);
  transition: 0.5s all ease;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  /* padding: 0 15px; */
}

.text-input-container.focused .text-input,
.text-input-container:hover .text-input {
  border-color: var(--wmg-color-grey);
}
/* .text-input-container.focused {
  border-color: var(--wmg-color-primary);
} */

.text-input-container.error .text-input {
  border-color: var(--wmg-color-error);
}

.text-input-container.disabled .text-input {
  border-color: var(--wmg-color-grey2);
}

.text-input-container.disabled .text-input input {
  border-color: var(--wmg-color-grey2);
}

.text-input .prefix {
  padding-left: 20px;
  margin-right: -25px;
}

.text-input .prepend {
  padding-left: 8px;
  margin-right: -25px;
}
.text-input .inline-label {
  opacity: 0.5;
  padding-left: 20px;
  white-space: nowrap;
}

.text-input input {
  margin: 0;
  border: none;
  border-radius: var(--wmg-border-radius-medium);
  text-align: left;
  width: 100%;
  padding: 15px 20px;
  font-family: var(--wmg-font-family);
  font-size: var(--wmg-text-body-size);
  line-height: var(--wmg-text-body-line-height);
  color: var(--wmg-color-white) !important;
  background-color: transparent !important;
  color-scheme: dark;
}

.text-input input[type="date"] {
  text-align: right;
}

.text-input input[type="date"]::-webkit-datetime-edit-text,
.text-input input[type="date"]::-webkit-datetime-edit-month-field,
.text-input input[type="date"]::-webkit-datetime-edit-day-field,
.text-input input[type="date"]::-webkit-datetime-edit-year-field {
  color: rgba(255, 255, 255, 0.25);
}

.text-input input[type="date"].input--has-value::-webkit-datetime-edit-text,
.text-input
  input[type="date"].input--has-value::-webkit-datetime-edit-month-field,
.text-input
  input[type="date"].input--has-value::-webkit-datetime-edit-day-field,
.text-input
  input[type="date"].input--has-value::-webkit-datetime-edit-year-field {
  color: #fff;
}

.text-input input[type="date"]:before {
  color: rgba(255, 255, 255, 0.5);
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

.text-input-container.with-icon .text-input {
  padding-left: 15px;
}

.text-input-container.with-icon input {
  padding: 15px 0;
}

.text-input input:-webkit-autofill {
  border: none;
  background-color: transparent !important;
}
.text-input input:focus {
  outline: none;
}

.text-input input:-ms-input-placeholder {
  color: var(--wmg-color-grey);
}

.text-input input::placeholder {
  color: var(--wmg-color-grey);
}

.text-input .icon-button {
  margin-right: 10px;
}

.button {
  position: relative;
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: var(--wmg-border-radius-medium);
  color: var(--wmg-color-background);
  background-color: var(--wmg-color-white);
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.button.sml {
  padding: 5px 10px;
  font-size: var(--wmg-text-caption-size);
  border-radius: var(--wmg-border-radius-small);
}

a.button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
a.button.row.secondary.disabled {
  background-color: rgb(47, 46, 46);
}
.button svg {
  fill: var(--wmg-color-background) !important;
}

.button.primary {
  background-color: var(--wmg-color-primary);
  color: var(--wmg-color-white);
}

.button.primary.disabled {
  background-color: var(--wmg-color-background3);
  color: var(--wmg-color-grey);
}

.button.primary.disabled svg {
  fill: var(--wmg-color-grey) !important;
}

.button.primary svg {
  fill: var(--wmg-color-white) !important;
}

.button.secondary {
  background-color: transparent;
  border-color: var(--wmg-color-grey);
  color: var(--wmg-color-white);
}
.button.secondary svg {
  fill: var(--wmg-color-white) !important;
}

.button.dark {
  background-color: var(--wmg-color-background);
  color: var(--wmg-color-white);
}
.button.dark.disabled {
  opacity: 0.5;
}
.button.dark svg {
  fill: var(--wmg-color-white) !important;
}

.button input[type="submit"] {
  display: none;
}

.error-message {
  background-color: rgba(241, 15, 42, 0.3);
  padding: 12px;
  border-radius: var(--wmg-border-radius-small);
}

.page-container {
  position: relative;
  grid-gap: 10px;
  gap: 10px;
  width: calc(100% - 40px);
  max-width: var(--wmg-max-page-width);
  align-self: center;
  padding: 100px 20px 50px 20px;
}

.pagination .page-item {
  box-sizing: border-box;
  display: flex;
  height: 30px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin: 0 2px;
  min-width: 30px;
}

.pagination a.page-item:hover,
.pagination .page-item.current {
  background-color: var(--wmg-color-background3);
}

.pagination .page-item.disabled {
  opacity: 0.5;
}
.pagination a.page-item.disabled:hover {
  background-color: transparent;
}

.pagination .page-item-spacer {
  margin: 0;
}

.tag-group {
  background-color: rgba(20, 60, 99, 0.3);
  border-radius: var(--wmg-border-radius-small);
  padding: 6px;
  color: rgba(255, 255, 255, 0.75);
}

.tag-group .tag.disabled {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.tag {
  background-color: var(--wmg-color-background3);
  color: var(--wmg-color-white);
  align-self: flex-start;
  border-radius: var(--wmg-border-radius-small);
  padding: 8px 16px;
  align-items: center;
  font-size: var(--wmg-text-caption-size);
  white-space: nowrap;
}

.tag.active {
  background-color: var(--wmg-color-white);
  color: var(--wmg-color-background);
}

.tag.selected {
  background-color: var(--wmg-color-secondary);
}

.tag.small {
  padding: 4px 8px;
}
.tag.medium {
  padding: 8px 16px;
}

.tag .count {
  border-radius: 4px;
  background-color: var(--wmg-color-white);
  color: var(--wmg-color-background3);
  padding: 0 6px;
  margin-right: -5px;
}

.tag.active .count {
  background-color: var(--wmg-color-background3);
  color: var(--wmg-color-white);
}

.tag .icon {
  margin-right: -5px;
}

.card {
  background-color: var(--wmg-color-background2);
  padding: 30px;
  border-radius: var(--wmg-border-radius-medium);
  overflow: hidden;
  overflow-y: auto;
  /* backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.3);
  border: 0.5px solid rgba(255, 255, 255, 0.1); */
}

.noscroll {
  overflow: hidden;
}

.sml {
  padding: 15px;
}

.modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: rgba(18, 18, 18, 0.6);
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
  z-index: 100;
}

.pop-over {
  position: absolute;
  background: var(--wmg-color-background);
  box-shadow: 8px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  overflow: hidden;
  z-index: 100;
  min-width: 75px;
  display: flex;
  top: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  border: 2px solid var(--wmg-color-background);
}

.pop-over.bottom {
  top: calc(100% + 10px);
  left: 0;
}
.pop-over.left {
  left: 0;
}
.pop-over.fullWidth {
  width: 100%;
}

.pop-over.light {
  background: var(--wmg-color-white);
}

.pop-over.light .pop-over-item > * {
  color: var(--wmg-color-background2);
}

.pop-over.light .pop-over-item svg {
  fill: var(--wmg-color-background2) !important;
}

.pop-over.light .pop-over-item:hover {
  background-color: rgba(242, 242, 242, 1);
}

.pop-over::-webkit-scrollbar {
  width: 10px;
}

.pop-over::-webkit-scrollbar-track {
  background-color: var(--wmg-color-background);
  border-radius: 20px;
}

.pop-over::-webkit-scrollbar-thumb {
  background-color: var(--wmg-color-grey);
  border: 3px solid var(--wmg-color-background);

  border-radius: 20px;
}
.pop-over.light::-webkit-scrollbar-track {
  background-color: transparent;
}

.pop-over.light::-webkit-scrollbar-thumb {
  background-color: var(--wmg-color-grey);
  border-color: var(--wmg-color-white);
}

.pop-over-item {
  padding: 10px 15px;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  transition: 0.5s all ease;
}
.pop-over-item:hover,
.pop-over-item.selected {
  background-color: var(--wmg-color-background2);
}

.pop-over-item {
  white-space: nowrap;
}

.artwork {
  flex: 1 1;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  transition: 1s all ease;
}

.artwork:hover {
  transform: scale(1.025);
}

.artist-card {
  cursor: pointer;
  margin-bottom: 10px;
}
.artist-card .card {
  padding: 0;
  height: 30vw;
  max-height: 400px;
}

.artist-card .card-content {
  overflow: hidden;
}

.artist-card .pop-over {
  grid-gap: 0;
  gap: 0;
}

.save-collection-container {
  position: relative;
}

.artist-card .save-collection-container {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  justify-content: flex-end;
  margin: 10px;
}

.artist-card .save-collection-button {
  display: flex;
  border-radius: var(--wmg-border-radius-xlarge);
  background-color: rgba(9, 9, 8, 0.35);
  justify-content: center;
  align-items: center;
  height: 48px;
  min-width: 48px;
  opacity: 0;
  transition: 0.5s opacity ease;
}

.save-collection-container.isAdded .save-collection-button,
.artist-card:hover .save-collection-button {
  opacity: 1;
}

.notification-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  margin: 10px;
  overflow: hidden;
}

.notification-wrapper {
  animation: appearFromBottom 0.35s ease;
  animation-timing-function: cubic-bezier(0.4, 1.21, 0.83, 1.16);
  background: rgba(0, 0, 0, 0.75);
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: var(--wmg-border-radius-medium);
}

@keyframes appearFromBottom {
  0% {
    opacity: 0;
    transform: translate(0, 100%);
  }
  0% {
    opacity: 0;
    transform: translate(0, 25%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.grid {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
}

.grid.cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid.cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid .card {
  /* height: 30vw; */
  min-height: 200px;
  /* max-height: 400px; */
  position: relative;
  border-radius: var(--wmg-border-radius-small);
}

.grid .card.collection .card-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0;
  gap: 0;
  grid-auto-rows: 50%;
}

.grid .card img {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
}

.sort-by-container {
  position: relative;
}

.row.center .total-counts {
  align-items: baseline;
}

.table {
  overflow: hidden;
}

table {
  width: 100%;
}

tr.gap td {
  padding: 10px 0;
}

tr.parent {
  position: -webkit-sticky !important;
  position: sticky !important;
  background-color: var(--wmg-color-background2);
  top: 0;
  z-index: 1;
}

tr.parent,
tr.child {
  position: relative;
}

tr.clickable:hover {
  cursor: pointer;
  background-color: var(--wmg-color-background2);
}

.table-header,
.table-content {
  padding-right: 20px;
}

.table-content {
  /* justify-content: center; */
  overflow-y: auto;
}

.table-content > .row {
  padding: 10px 0;
  border-bottom: 1px solid var(--wmg-color-background3);
}

.table-content > .row:last-child {
  border-bottom: 0;
}

.table-content::-webkit-scrollbar {
  transform: translateX(-10px);
  width: 5px;
}

.table-content::-webkit-scrollbar-track {
  background-color: var(--wmg-color-grey2);
  border-radius: 20px;
}

.table-content::-webkit-scrollbar-thumb {
  background-color: var(--wmg-color-grey);
  border-radius: 20px;
  /* border: 1px solid var(--wmg-color-grey2); */
}

.search-bar.fixed {
  position: fixed;
  max-width: var(--wmg-max-page-width);
  width: 100%;
  z-index: 90;
  background: var(--wmg-color-background);
  padding-top: 110px;
  top: 0;
  padding-bottom: 10px;
}

.search-bar.compact .text-input {
  background-color: var(--wmg-color-grey2);
  padding-left: 10px;
}

.search-bar.compact .text-input input {
  padding: 6px 0;
  font-size: var(--wmg-text-caption-size);
  line-height: var(--wmg-text-caption-line-height);
}

.indentation {
  padding: 2px;
  background-color: #666666;
  border-radius: 5px;
  margin-left: -12px;
  height: 95%;
  position: absolute;
}

.child.selected > td {
  background-color: transparent;
}
.child.selected a span {
  color: var(--wmg-color-white);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.child.selected a span:hover {
  text-decoration: underline;
}

.selected > td {
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  border-radius: 8px;
}
.selected a {
  color: #000;
}

.row-count {
  align-self: center;
  background-color: white;
  justify-content: center;
  font-size: 12px;
  border-radius: 4px;
  min-width: 15px;
  max-width: 20px;
  max-height: 15px;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px;
  color: #000;
}

.filters-container .drawer {
  position: absolute;
  transform: translateY(70px);
  background: var(--wmg-color-background2);
  border-bottom-left-radius: var(--wmg-border-radius-small);
  border-bottom-right-radius: var(--wmg-border-radius-small);
  left: 0;
  right: 0;
  min-height: 200px;
  max-height: 400px;
  z-index: 10;
  top: 0;
  padding: 20px;
  display: none;
  overflow: hidden;
}

.filters-container {
  position: relative;
  padding: 20px;
  margin-right: -20px;
  border-top-left-radius: var(--wmg-border-radius-small);
  border-top-right-radius: var(--wmg-border-radius-small);
}

.filters-container.active {
  background: var(--wmg-color-background2);
}

.filters-container.active .drawer {
  display: flex;
}

.search-and-filters {
  justify-content: space-between;
}

.search-and-filters .search-bar {
  flex: 0.75 1;
}

.no-cursor {
  cursor: none;
}

.alert {
  color: rgb(170, 113, 175);
}

.artist-detail-background {
  position: fixed;
  top: 0;
  background-size: cover;
  min-width: 100vw;
  height: 100vh;
  max-height: 1000px;
  background-repeat: no-repeat;
  background-position: center center;
}
.artist-detail-header {
  position: fixed;
  width: 100vw;
  top: 92px;
  padding: 0 0 20px 0;
  z-index: 90;
}
.artist-detail-header h1 {
  transition: 0.5s all ease-in-out;
}

.artist-detail-header.scrolled {
  /* background: var(--wmg-color-background); */
  transform: translate3d(0, -20px, 0);
  background: rgba(18, 18, 18, 0.6);
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
  top: 97px;
}
.artist-detail-header.scrolled h1,
.artist-detail-header.scrolled.smaller h1 {
  font-size: var(--wmg-text-h2-size);
  line-height: var(--wmg-text-h2-line-height);
}

.artist-detail-header.smaller h1 {
  font-size: var(--wmg-text-h1-small-size);
  line-height: var(--wmg-text-h1-small-line-height);
}

.artist-detail-header > .row {
  align-self: center;
  max-width: var(--wmg-max-page-width);
  width: calc(100% - 40px);
}

.export-option-container {
  position: relative;
}

.artist-card .export-option-container {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  justify-content: flex-end;
  margin: 10px;
}

.export .pop-over {
  width: 175px;
  left: auto;
  right: -12px;
}

.sort-by-container {
  position: relative;
}

.sort-by-container .button {
  min-width: 200px;
}

.chart-card .card {
  min-height: 250px;
}
.chart-card-header {
  grid-gap: 10px;
  gap: 10px;
}

.age-gender-charts .recharts-surface {
  position: absolute !important;
  left: 0;
  top: 0;
}

.age-gender-charts .recharts-wrapper svg {
  overflow: inherit !important;
}

.age-gender-charts .custom-tooltip {
  background: var(--wmg-color-grey2);
  border-radius: var(--wmg-border-radius-medium);
  padding: 8px;
}

.age-gender-charts .recharts-tooltip-wrapper,
.age-gender-charts .recharts-tooltip-wrapper div {
  display: block;
}

.age-gender-charts .custom-tooltip h2 {
  font-size: var(--wmg-text-body-size);
  line-height: var(--wmg-text-body-line-height);
  font-weight: bold;
  margin: 0;
  color: var(--wmg-color-grey);
}

.age-gender-charts .custom-tooltip div {
  margin-top: 4px;
}

.age-gender-charts .custom-tooltip h3 {
  font-size: var(--wmg-text-caption-size);
  line-height: var(--wmg-text-caption-line-height);
  font-weight: bold;
  margin: 0;
  color: var(--wmg-color-grey);
}

.age-gender-charts .custom-tooltip p {
  font-size: var(--wmg-text-caption-small-size);
  line-height: var(--wmg-text-caption-small-line-height);
  margin: 0;
}

.tooltip {
  background-color: var(--wmg-color-primary);
  font-size: var(--wmg-text-caption-size);
  border-radius: 10px;
  padding: 5px 8px;
  transform: translateX(-65%);
}

.tooltip .label {
  opacity: 0.6;
  text-align: right;
  width: 45px;
}

.date-tooltip {
  --chart-height: 200px;
  position: absolute;
  transform: translateY(calc(var(--chart-height) + 8px)) translateX(-95%);
  background-color: var(--wmg-color-background2);
  font-size: var(--wmg-text-caption-size);
}

.dateRangeFilter {
  padding: 20px 10%;
  justify-content: space-between;
}
.filterItem {
  padding: 2px 10px;
  border-radius: var(--wmg-border-radius-small);
  background-color: var(--wmg-color-grey2);
  color: var(--wmg-color-grey);
}
.filterItem:hover {
  color: var(--wmg-color-white);
}

.filterItem.active {
  background-color: #565656;
  color: var(--wmg-color-white);
}

.table-card .card {
  min-height: 400px;
  max-height: 400px;
  overflow: hidden;
}
.table-card-header {
  grid-gap: 10px;
  gap: 10px;
}

.table-card .card-content,
.table-card .table {
  flex-grow: 1;
  overflow: hidden;
}

.table-card .card-content {
  grid-gap: 10px;
  gap: 10px;
}

.table-card .card .export-png {
  max-height: 800px;
  height: 800px;
}

.map-svg {
  fill: var(--wmg-color-grey);
  align-items: center;
}

.hover-country {
  fill: var(--wmg-color-primary);
}

.map-file {
  margin-top: 30px;
}

.country-row:hover {
  background: var(--wmg-color-secondary);
  border-radius: 10px;
  border: 1px solid var(--wmg-color-primary);
}

.table-content .country-row {
  padding: 10px;
}

.country-table .table-header {
  padding-right: 30px;
  padding-top: 10px;
}

.country-table {
  height: 90%;
}

.table-header .sortable-header {
  align-items: center;
}

.table-header .sortable-header.right {
  justify-content: flex-end;
}

.table-header .sortable-header.left {
  justify-content: flex-start;
}

.table-header .sortable-header.hidden {
  display: none;
}

.chart-heading {
  flex-direction: row;
}
.chart-name {
  font-size: var(--wmg-text-body-size);
  color: var(--wmg-color-white);
}

.chart-scale {
  font-size: var(--wmg-text-caption-size);
  color: var(--wmg-color-grey);
  font-weight: var(--wmg-text-weight-bold);
}

.engagement-context-chart .recharts-surface {
  position: absolute !important;
  left: 0;
  top: 0;
}

.engagement-context-chart .recharts-wrapper svg {
  overflow: inherit !important;
}

.engagement-context-chart .custom-tooltip {
  background: var(--wmg-color-grey2);
  border-radius: var(--wmg-border-radius-medium);
  padding: 8px;
}

.engagement-context-chart .recharts-tooltip-wrapper,
.engagement-context-chart .recharts-tooltip-wrapper div {
  display: block;
}

.engagement-context-chart .custom-tooltip h2 {
  font-size: var(--wmg-text-body-size);
  line-height: var(--wmg-text-body-line-height);
  font-weight: bold;
  margin: 0;
  color: var(--wmg-color-grey);
}

.engagement-context-chart .custom-tooltip div {
  margin-top: 4px;
  max-width: 200px;
}

.engagement-context-chart .custom-tooltip p {
  font-size: var(--wmg-text-caption-small-size);
  line-height: var(--wmg-text-caption-small-line-height);
  margin: 0;
}

:root {
  --wmg-color-background: rgba(18, 18, 18, 1);
  --wmg-color-background2: rgba(28, 28, 28, 1);
  --wmg-color-background3: rgba(45, 45, 45, 1);
  --wmg-color-background4: rgba(31, 32, 33, 1);
  --wmg-color-primary: rgba(13, 113, 207, 1);
  --wmg-color-primary2: rgba(14, 127, 232, 1);
  --wmg-color-secondary: rgba(20, 60, 99, 1);
  --wmg-color-error: rgba(241, 15, 42, 1);
  --wmg-color-white: rgba(255, 255, 255, 1);
  --wmg-color-grey: rgba(130, 130, 130, 1);
  --wmg-color-grey2: rgba(48, 48, 48, 1);
  --wmg-font-family: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --wmg-text-h1-size: 64px;
  --wmg-text-h1-line-height: 96px;
  --wmg-text-h1-small-size: 36px;
  --wmg-text-h1-small-line-height: 58px;
  --wmg-text-h2-size: 32px;
  --wmg-text-h2-line-height: 48px;
  --wmg-text-h3-size: 24px;
  --wmg-text-h3-line-height: 36px;
  --wmg-text-h4-size: 20px;
  --wmg-text-h4-line-height: 30px;
  --wmg-text-h5-size: 20px;
  --wmg-text-h5-line-height: 30px;
  --wmg-text-body-size: 14px;
  --wmg-text-body-line-height: 20px;
  --wmg-text-caption-size: 13px;
  --wmg-text-caption-line-height: 20px;
  --wmg-text-caption-small-size: 11px;
  --wmg-text-caption-small-line-height: 18px;
  --wmg-text-weight: 500;
  --wmg-text-weight-bold: 700;
  --wmg-border-radius-xsmall: 4px;
  --wmg-border-radius-small: 8px;
  --wmg-border-radius-medium: 12px;
  --wmg-border-radius-large: 16px;
  --wmg-border-radius-xlarge: 24px;
  --wmg-max-page-width: 1264px;
}

#root {
  display: flex;
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: var(--wmg-font-family);
  font-size: 14px;
  font-size: var(--wmg-text-body-size);
  font-weight: 500;
  font-weight: var(--wmg-text-weight);
  line-height: 20px;
  line-height: var(--wmg-text-body-line-height);
  background-color: rgba(18, 18, 18, 1);
  background-color: var(--wmg-color-background);
  color: rgba(255, 255, 255, 1);
  color: var(--wmg-color-white);
}

/* Works on Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: rgba(48, 48, 48, 1) rgba(130, 130, 130, 1);
  scrollbar-color: var(--wmg-color-grey2) var(--wmg-color-grey);
}

/* Works on Chrome, Edge, and Safari */
body::-webkit-scrollbar {
  width: 14px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(130, 130, 130, 1);
  background-color: var(--wmg-color-grey);
  border-radius: 20px;
  border: 4px solid rgba(18, 18, 18, 1);
  border: 4px solid var(--wmg-color-background);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

h1 {
  font-size: 64px;
  font-size: var(--wmg-text-h1-size);
  font-weight: 700;
  font-weight: var(--wmg-text-weight-bold);
  line-height: 96px;
  line-height: var(--wmg-text-h1-line-height);
}

h2 {
  font-size: 32px;
  font-size: var(--wmg-text-h2-size);
  font-weight: 700;
  font-weight: var(--wmg-text-weight-bold);
  line-height: 48px;
  line-height: var(--wmg-text-h2-line-height);
}

h3 {
  font-size: 24px;
  font-size: var(--wmg-text-h3-size);
  font-weight: 700;
  font-weight: var(--wmg-text-weight-bold);
  line-height: 36px;
  line-height: var(--wmg-text-h3-line-height);
}

h4 {
  font-size: 20px;
  font-size: var(--wmg-text-h4-size);
  font-weight: 700;
  font-weight: var(--wmg-text-weight-bold);
  line-height: 30px;
  line-height: var(--wmg-text-h4-line-height);
}

h5 {
  font-size: 20px;
  font-size: var(--wmg-text-h5-size);
  font-weight: 500;
  font-weight: var(--wmg-text-weight);
  line-height: 30px;
  line-height: var(--wmg-text-h5-line-height);
}

a {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  color: var(--wmg-color-white);
}
a.underline:hover {
  text-decoration: underline;
}

div {
  display: flex;
}

.bold {
  font-weight: 700;
  font-weight: var(--wmg-text-weight-bold);
}

.caption {
  font-size: 13px;
  font-size: var(--wmg-text-caption-size);
  line-height: 20px;
  line-height: var(--wmg-text-caption-line-height);
}
.caption.small {
  font-size: 11px;
  font-size: var(--wmg-text-caption-small-size);
  line-height: 18px;
  line-height: var(--wmg-text-caption-small-line-height);
}

.primary {
  color: rgba(13, 113, 207, 1);
  color: var(--wmg-color-primary);
}

.secondary {
  color: rgba(130, 130, 130, 1);
  color: var(--wmg-color-grey);
}

.placeholder {
  background-color: rgba(28, 28, 28, 1);
  background-color: var(--wmg-color-background2);
  color: rgba(28, 28, 28, 1);
  color: var(--wmg-color-background2);
  border-radius: 8px;
  border-radius: var(--wmg-border-radius-small);
  animation: pulse 1.5s infinite ease-in-out;
}

h1.placeholder,
h2.placeholder,
h3.placeholder,
h4.placeholder,
h5.placeholder {
  align-self: flex-start;
}

.app-container {
  flex: 1 1;
}

.no-auth-app-container {
  align-items: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: "#dddddd";
}

.flex {
  display: flex;
  flex: 1 1;
}

.wrap {
  flex-wrap: wrap;
}

.no-wrap {
  white-space: nowrap;
}

.grow {
  flex-grow: 1;
}

.row {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row.center,
.column.center {
  align-items: center;
}

.column.end {
  align-items: flex-end;
}
.row.end {
  align-items: flex-end;
  justify-content: flex-end;
}

.v-spacing {
  margin-top: 20px;
  margin-bottom: 20px;
}

.gap-s {
  grid-gap: 5px;
  gap: 5px;
}

.gap {
  grid-gap: 10px;
  gap: 10px;
}

.gap-m {
  grid-gap: 20px;
  gap: 20px;
}

.gap-l {
  grid-gap: 30px;
  gap: 30px;
}

.gap-xl {
  grid-gap: 40px;
  gap: 40px;
}

.scrollbar {
  overflow-y: auto;
  padding-right: 20px;
}
.scrollbar::-webkit-scrollbar {
  transform: translateX(-10px);
  width: 5px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: rgba(48, 48, 48, 1);
  background-color: var(--wmg-color-grey2);
  border-radius: 20px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(130, 130, 130, 1);
  background-color: var(--wmg-color-grey);
  border-radius: 20px;
  /* border: 1px solid var(--wmg-color-grey2); */
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.sticky-footer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

table td {
  padding: 5px 0;
  vertical-align: top;
}

.svg-item {
  position: relative;
  animation: donutfade 1s;
  min-width: 75%;
  min-height: 220px;
  justify-content: center;
  align-items: center;
}

.round {
  overflow: hidden;
  position: absolute;
  transform: rotate(-90deg);
  transition: all 1s ease-in-out;
  fill: transparent;
  /* SVG */
  stroke-width: 8;
  stroke-linecap: butt;
  stroke-dasharray: 0 999;
}

.text-label {
  position: absolute;
  text-anchor: middle;
}

.simpleTip .react-tooltip-lite {
  border: none;
  border-radius: var(--wmg-border-radius-medium);
  background: var(--wmg-color-grey2);
  color: var(--wmg-color-grey);
  font-size: var(--wmg-text-caption-size);
}

.simpleTip .react-tooltip-lite-arrow {
  border-color: var(--wmg-color-grey2);
  position: relative;
}

.simpleTip .react-tooltip-lite-arrow::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  z-index: 99;
  display: block;
}

.simpleTip .react-tooltip-lite-up-arrow::before {
  border-top: 10px solid var(--wmg-color-grey2);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  top: -11px;
}

.simpleTip .react-tooltip-lite-down-arrow::before {
  border-bottom: 10px solid var(--wmg-color-grey2);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  bottom: -11px;
}

.simpleTip .react-tooltip-lite-right-arrow::before {
  border-right: 10px solid var(--wmg-color-grey2);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -11px;
  top: -10px;
}

.simpleTip .react-tooltip-lite-left-arrow::before {
  border-left: 10px solid var(--wmg-color-grey2);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -11px;
  top: -10px;
}

.artist-card .delete-button {
  display: none;
}

.artist-card:hover .delete-button {
  display: inherit;
}

.checkbox {
  border: 1px solid var(--wmg-color-grey);
  border-radius: var(--wmg-border-radius-xsmall);
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.checkbox.checked {
  border: 1px solid var(--wmg-color-primary);
  background-color: var(--wmg-color-primary);
}

.nav-item {
  border-bottom: 2px solid transparent;
}

.nav-item,
.nav-item > a {
  transition: 0.75s all ease;
}

.nav-item:hover,
.nav-item.active {
  border-bottom-color: var(--wmg-color-white);
}

.nav-item > a {
  color: rgba(255, 255, 255, 0.5);
}

.nav-item:hover > a,
.nav-item.active > a {
  color: var(--wmg-color-white);
}

.avatar {
  height: 40px;
  width: 40px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.15);
  justify-content: center;
  align-items: center;
  transition: 0.75s all ease;
}

.avatar:hover {
  background-color: rgba(255, 255, 255, 0.25);
}

.avatar.clickable {
  cursor: pointer;
}

.app-container .app-header-container {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100vw;
  justify-content: center;
}

.app-container .app-header-container.scrolled {
  /* background: var(--wmg-color-background); */
  background: rgba(18, 18, 18, 0.6);
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
}
.app-container .app-header {
  padding: 20px 20px 20px 20px;
  max-width: var(--wmg-max-page-width);
  width: calc(100% - 40px);
}

.app-container .app-navigation {
  justify-content: center;
}

