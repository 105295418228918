.modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background: rgba(18, 18, 18, 0.6);
  backdrop-filter: blur(16px);
  z-index: 100;
}
