.tooltip {
  background-color: var(--wmg-color-primary);
  font-size: var(--wmg-text-caption-size);
  border-radius: 10px;
  padding: 5px 8px;
  transform: translateX(-65%);
}

.tooltip .label {
  opacity: 0.6;
  text-align: right;
  width: 45px;
}

.date-tooltip {
  --chart-height: 200px;
  position: absolute;
  transform: translateY(calc(var(--chart-height) + 8px)) translateX(-95%);
  background-color: var(--wmg-color-background2);
  font-size: var(--wmg-text-caption-size);
}
