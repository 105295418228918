.simpleTip .react-tooltip-lite {
  border: none;
  border-radius: var(--wmg-border-radius-medium);
  background: var(--wmg-color-grey2);
  color: var(--wmg-color-grey);
  font-size: var(--wmg-text-caption-size);
}

.simpleTip .react-tooltip-lite-arrow {
  border-color: var(--wmg-color-grey2);
  position: relative;
}

.simpleTip .react-tooltip-lite-arrow::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  z-index: 99;
  display: block;
}

.simpleTip .react-tooltip-lite-up-arrow::before {
  border-top: 10px solid var(--wmg-color-grey2);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  top: -11px;
}

.simpleTip .react-tooltip-lite-down-arrow::before {
  border-bottom: 10px solid var(--wmg-color-grey2);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: -10px;
  bottom: -11px;
}

.simpleTip .react-tooltip-lite-right-arrow::before {
  border-right: 10px solid var(--wmg-color-grey2);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -11px;
  top: -10px;
}

.simpleTip .react-tooltip-lite-left-arrow::before {
  border-left: 10px solid var(--wmg-color-grey2);
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -11px;
  top: -10px;
}
