.button {
  position: relative;
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: var(--wmg-border-radius-medium);
  color: var(--wmg-color-background);
  background-color: var(--wmg-color-white);
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button.sml {
  padding: 5px 10px;
  font-size: var(--wmg-text-caption-size);
  border-radius: var(--wmg-border-radius-small);
}

a.button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
a.button.row.secondary.disabled {
  background-color: rgb(47, 46, 46);
}
.button svg {
  fill: var(--wmg-color-background) !important;
}

.button.primary {
  background-color: var(--wmg-color-primary);
  color: var(--wmg-color-white);
}

.button.primary.disabled {
  background-color: var(--wmg-color-background3);
  color: var(--wmg-color-grey);
}

.button.primary.disabled svg {
  fill: var(--wmg-color-grey) !important;
}

.button.primary svg {
  fill: var(--wmg-color-white) !important;
}

.button.secondary {
  background-color: transparent;
  border-color: var(--wmg-color-grey);
  color: var(--wmg-color-white);
}
.button.secondary svg {
  fill: var(--wmg-color-white) !important;
}

.button.dark {
  background-color: var(--wmg-color-background);
  color: var(--wmg-color-white);
}
.button.dark.disabled {
  opacity: 0.5;
}
.button.dark svg {
  fill: var(--wmg-color-white) !important;
}

.button input[type="submit"] {
  display: none;
}
