.pop-over {
  position: absolute;
  background: var(--wmg-color-background);
  box-shadow: 8px 8px 32px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  overflow: hidden;
  z-index: 100;
  min-width: 75px;
  display: flex;
  top: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  border: 2px solid var(--wmg-color-background);
}

.pop-over.bottom {
  top: calc(100% + 10px);
  left: 0;
}
.pop-over.left {
  left: 0;
}
.pop-over.fullWidth {
  width: 100%;
}

.pop-over.light {
  background: var(--wmg-color-white);
}

.pop-over.light .pop-over-item > * {
  color: var(--wmg-color-background2);
}

.pop-over.light .pop-over-item svg {
  fill: var(--wmg-color-background2) !important;
}

.pop-over.light .pop-over-item:hover {
  background-color: rgba(242, 242, 242, 1);
}

.pop-over::-webkit-scrollbar {
  width: 10px;
}

.pop-over::-webkit-scrollbar-track {
  background-color: var(--wmg-color-background);
  border-radius: 20px;
}

.pop-over::-webkit-scrollbar-thumb {
  background-color: var(--wmg-color-grey);
  border: 3px solid var(--wmg-color-background);

  border-radius: 20px;
}
.pop-over.light::-webkit-scrollbar-track {
  background-color: transparent;
}

.pop-over.light::-webkit-scrollbar-thumb {
  background-color: var(--wmg-color-grey);
  border-color: var(--wmg-color-white);
}

.pop-over-item {
  padding: 10px 15px;
  gap: 10px;
  align-items: center;
  transition: 0.5s all ease;
}
.pop-over-item:hover,
.pop-over-item.selected {
  background-color: var(--wmg-color-background2);
}

.pop-over-item {
  white-space: nowrap;
}
