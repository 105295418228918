.grid {
  display: grid;
  gap: 20px;
}

.grid.cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid.cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid .card {
  /* height: 30vw; */
  min-height: 200px;
  /* max-height: 400px; */
  position: relative;
  border-radius: var(--wmg-border-radius-small);
}

.grid .card.collection .card-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  grid-auto-rows: 50%;
}

.grid .card img {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
}
