.dateRangeFilter {
  padding: 20px 10%;
  justify-content: space-between;
}
.filterItem {
  padding: 2px 10px;
  border-radius: var(--wmg-border-radius-small);
  background-color: var(--wmg-color-grey2);
  color: var(--wmg-color-grey);
}
.filterItem:hover {
  color: var(--wmg-color-white);
}

.filterItem.active {
  background-color: #565656;
  color: var(--wmg-color-white);
}
