.artwork {
  flex: 1;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  transition: 1s all ease;
}

.artwork:hover {
  transform: scale(1.025);
}
