.checkbox {
  border: 1px solid var(--wmg-color-grey);
  border-radius: var(--wmg-border-radius-xsmall);
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.checkbox.checked {
  border: 1px solid var(--wmg-color-primary);
  background-color: var(--wmg-color-primary);
}
