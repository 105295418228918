.artist-card {
  cursor: pointer;
  margin-bottom: 10px;
}
.artist-card .card {
  padding: 0;
  height: 30vw;
  max-height: 400px;
}

.artist-card .card-content {
  overflow: hidden;
}

.artist-card .pop-over {
  gap: 0;
}
