.card {
  background-color: var(--wmg-color-background2);
  padding: 30px;
  border-radius: var(--wmg-border-radius-medium);
  overflow: hidden;
  overflow-y: auto;
  /* backdrop-filter: blur(30px);
  background-color: rgba(0, 0, 0, 0.3);
  border: 0.5px solid rgba(255, 255, 255, 0.1); */
}

.noscroll {
  overflow: hidden;
}

.sml {
  padding: 15px;
}
