.selected > td {
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  border-radius: 8px;
}
.selected a {
  color: #000;
}

.row-count {
  align-self: center;
  background-color: white;
  justify-content: center;
  font-size: 12px;
  border-radius: 4px;
  min-width: 15px;
  max-width: 20px;
  max-height: 15px;
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px;
  color: #000;
}
