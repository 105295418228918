.notification-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  margin: 10px;
  overflow: hidden;
}

.notification-wrapper {
  animation: appearFromBottom 0.35s ease;
  animation-timing-function: cubic-bezier(0.4, 1.21, 0.83, 1.16);
  background: rgba(0, 0, 0, 0.75);
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: var(--wmg-border-radius-medium);
}

@keyframes appearFromBottom {
  0% {
    opacity: 0;
    transform: translate(0, 100%);
  }
  0% {
    opacity: 0;
    transform: translate(0, 25%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
