.age-gender-charts .recharts-surface {
  position: absolute !important;
  left: 0;
  top: 0;
}

.age-gender-charts .recharts-wrapper svg {
  overflow: inherit !important;
}

.age-gender-charts .custom-tooltip {
  background: var(--wmg-color-grey2);
  border-radius: var(--wmg-border-radius-medium);
  padding: 8px;
}

.age-gender-charts .recharts-tooltip-wrapper,
.age-gender-charts .recharts-tooltip-wrapper div {
  display: block;
}

.age-gender-charts .custom-tooltip h2 {
  font-size: var(--wmg-text-body-size);
  line-height: var(--wmg-text-body-line-height);
  font-weight: bold;
  margin: 0;
  color: var(--wmg-color-grey);
}

.age-gender-charts .custom-tooltip div {
  margin-top: 4px;
}

.age-gender-charts .custom-tooltip h3 {
  font-size: var(--wmg-text-caption-size);
  line-height: var(--wmg-text-caption-line-height);
  font-weight: bold;
  margin: 0;
  color: var(--wmg-color-grey);
}

.age-gender-charts .custom-tooltip p {
  font-size: var(--wmg-text-caption-small-size);
  line-height: var(--wmg-text-caption-small-line-height);
  margin: 0;
}
