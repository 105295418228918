.search-and-filters {
  justify-content: space-between;
}

.search-and-filters .search-bar {
  flex: 0.75;
}

.no-cursor {
  cursor: none;
}

.alert {
  color: rgb(170, 113, 175);
}
